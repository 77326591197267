$(document).ready(function () {
    if($('#print').length > 0) {
        $('#print').click(function() {
            window.print();
        });
    }

    if($('#schools').length > 0) {
        $('select[name=school_name]').bind('change', function() {
            $('#schools img').fadeOut(300, 'linear', fadeLogo);
        });
    }

    $('.modaal_trigger_bill').each(function(){
        var that = $(this);
        $(this).modaal({
            type: 'inline',
            before_open: function(){
                            if (window.invoicemodalhooks !== undefined) {
                                for(var i in window.invoicemodalhooks){
                                    window.invoicemodalhooks[i](that);
                                }
                            }
                        },
            after_open: setDatePicker,
            before_close: function() {
                $('.datepicker').datepicker( "destroy" );
                $('.datepicker').removeClass("hasDatepicker");
            }
        });
    });

    if($('.modaal_ajax').length > 0) {
        $('.modaal_ajax').modaal({
            type: 'ajax'
        });
    }

    if($('.delete, .delete_icon').length > 0) {
        $('.delete, .delete_icon').each(function(){
            var that = $(this);
            $(this).modaal({
                type: 'confirm',
                confirm_button_text: $(this).data('modal-confirm-title'),
                confirm_cancel_button_text: $(this).data('modal-cancel-title'),
                confirm_title: $(this).data('modal-title'),
                confirm_content: $(this).data('modal-description'),
                confirm_callback: function(url) {
                    window.location.href = that.data('modal-confirm-url');
                }
            });
        });
    }

    addOperation();
});

var setDatePicker = function(){
    if($('.datepicker').length > 0) {
        $('.datepicker').each(function(){
            var that = $(this);
            if(!$(this).hasClass('hasDatepicker')) {
                $(this).datepicker({
                    showOn: 'button',
                    buttonImage: that.data('img-src'), // File (and file path) for the calendar image
                    buttonImageOnly: false,
                    buttonText: 'Voir le calendrier',
                    showButtonPanel: true,
                    closeText: 'Fermer le calendrier',
                    onClose: removeAria,
                    firstDay: 1,
                    altField: ".datepicker",
                    prevText: 'Mois précédent',
                    nextText: 'Mois suivant',
                    currentText: 'Aujourd\'hui',
                    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                    monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
                    dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                    dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
                    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
                    weekHeader: 'Sem.',
                    dateFormat: 'dd.mm.yy'
                });
            }
        });

        // Add aria-describedby to the button referring to the label
        $('.ui-datepicker-trigger').attr('aria-describedby', 'datepickerLabel');
        dayTripper();
    }
}

var fadeLogo = function() {
    $('#schools img.'+$('select[name=school_name]').val().toLowerCase()).fadeIn(2600);
}
